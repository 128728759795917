import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Layout from "../views/layout.vue";
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy.vue";

const routes = [
  {
    path: '/',
    name: 'layout',
    redirect: { path: "/start" },
    component: Layout,
    children: [
      {
        path: '/start',
        component: HomeView
      },      
    ]
  },
  {
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  
]

const router = createRouter({
  routes,
  history: createWebHashHistory()
})

export default router
